<template>
  <div class="ant-card" id="teach_tag">
    <div class="ant-card-body">
      <div class="head-box">
        <div class="table-operator">
          <a-button
            icon="plus"
            type="primary"
            @click="onAdd"
            v-show="isShowAdd"
            >添加标签</a-button
          >
        </div>
      </div>
      <div class="contant-box">
        <a-table
          ref="table"
          :columns="columns"
          :data-source="tableList"
          :loading="tableLoading"
          :pagination="false"
        >
          <span slot="action" slot-scope="text, record">
            <template>
              <a v-show="isShowUpd" class="btn-edit" @click="onEdit(record)"
                >编辑</a
              >
              <a v-show="isShowDel" class="btn-delete" @click="onDel(record)"
                >删除</a
              >
            </template>
          </span>
        </a-table>
        <!-- 分页功能 -->
        <MyPagination
          :count="count"
          :pageNo="pageNo"
          @showSizeChangeFn="showSizeChangeFn"
        ></MyPagination>
      </div>
    </div>
    <a-modal
      title="新建/编辑标签"
      :width="640"
      :visible="visible"
      :confirmLoading="confirmLoading"
      okText="确定"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="tapForm"
        :model="tapform"
        :rules="rules"
        :label-col="{ span: 7 }"
        :wrapperCol="{ span: 13 }"
      >
        <a-form-model-item label="标签名称" prop="labelText">
          <a-input v-model="tapform.labelText">
            <div slot="addonAfter">{{ tapform.labelText.length }}/8</div>
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 返回顶部 -->
    <a-back-top :target="targetFn" :visibilityHeight="100" />
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import { Modal, message } from "ant-design-vue";
import {
  TagListApi,
  TagDelApi,
  UpdLabelApi,
  SaveLabelApi,
} from "@/request/api/teachersManage";
import { codeFn } from "@/utils/tools";
export default {
  created() {
    if (codeFn("/admin/course/label/saveLabel")) this.isShowAdd = true;
    if (codeFn("/admin/course/label/updLabel")) this.isShowUpd = true;
    if (codeFn("/admin/course/label/delLabel")) this.isShowDel = true;
  },
  components: { MyPagination },
  data() {
    return {
      columns: [
        {
          title: "标签名称",
          dataIndex: "labelText",
          key: "labelText",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "150px",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableList: [],
      tableLoading: false,
      pageNo: 1,
      pageSize: 20,
      count: 1,
      // create model
      tapform: {
        id: "",
        labelText: "",
        labelType: 2,
      },
      rules: {
        labelText: [
          { min: 1, max: 8, message: "最多输入8个字符！", trigger: "blur" },
        ],
      },
      visible: false,
      confirmLoading: false,
      isShowAdd: false, // 修改是否隐藏
      isShowUpd: false, // 新增是否隐藏
      isShowDel: false, // 删除是否隐藏
    };
  },
  mounted() {
    this.getTagList();
  },
  methods: {
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#teach_tag");
    },
    // 标签列表数据
    getTagList() {
      this.tableLoading = true;
      TagListApi({
        labelType: 2,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code === 200) {
          let newList = res.data.data;
          newList.map((item) => {
            item.key = item.id;
          });
          this.tableList = newList;
          this.count = res.data.count;
        }
        this.tableLoading = false;
      });
    },
    // 分页切换页数
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.getTagList();
    },
    // 添加标签
    onAdd() {
      this.visible = true;
    },
    // 编辑标签
    onEdit(record) {
      this.visible = true;
      this.tapform.id = record.id;
      this.tapform.labelText = record.labelText;
    },
    // 删除标签
    onDel(record) {
      const that = this;
      Modal.confirm({
        title: "删除标签后不可恢复，是否确定删除？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          TagDelApi({ labelNo: record.labelNo, type: 2 }).then((res) => {
            if (res.code === 200) {
              message.success("删除成功");
              that.getTagList();
            }
          });
        },
      });
    },
    // 标签提交
    handleOk() {
      this.confirmLoading = true;
      this.$refs.tapForm.validate((valid) => {
        if (valid) {
          if (this.tapform.id > 0) {
            UpdLabelApi(this.tapform).then((res) => {
              if (res.code === 200) {
                this.visible = false;
                this.confirmLoading = false;
                this.$refs.tapForm.resetFields(); // 重置表格
                message.success("修改成功");
                this.getTagList();
              } else {
                setTimeout(() => {
                  this.confirmLoading = false;
                }, 2000);
              }
            });
          } else {
            SaveLabelApi(this.tapform).then((res) => {
              if (res.code === 200) {
                this.visible = false;
                this.confirmLoading = false;
                this.$refs.tapForm.resetFields(); // 重置表格
                message.success("新增成功");
                this.getTagList();
              } else {
                setTimeout(() => {
                  this.confirmLoading = false;
                }, 2000);
              }
            });
          }
        }
      });
    },
    // 标签取消或关闭
    handleCancel() {
      this.visible = false;
      this.$refs.tapForm.resetFields(); // 重置表格
    },
  },
};
</script>
 
<style lang="less" scoped>
#teach_tag {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.head-box {
  margin-bottom: 30px;
}
.btn-edit {
  margin-right: 30px;
}
</style>